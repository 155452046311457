/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useStaticQuery, graphql} from "gatsby"

import PageWidth from "./page-width"
import FooterList from "./footer-list"

import {Theme} from "../styles/theme"

const Footer = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query FooterQuery {
      sections: allDatoCmsFooterSection {
        edges {
          node {
            id
            name
            orderInFooter
          }
        }
      }
    }
  `)

  const sortedFooterSections = data.sections.edges.sort(
    (a, b) => a.node.orderInFooter - b.node.orderInFooter,
  )

  return (
    <footer css={FooterStyles}>
      <PageWidth>
        <nav>
          <div className="logo">
            <figure>
              <a href="/">
                <img src="/logo.svg" />
              </a>
            </figure>
            <span>
              Copyright © {new Date().getFullYear()} Relevant. All rights
              reserved.
            </span>
          </div>
          <div className="footer-lists">
            {sortedFooterSections.map(section => (
              <FooterList key={section.node.id} {...section.node} />
            ))}
          </div>
        </nav>
      </PageWidth>
    </footer>
  )
}

export default Footer

const FooterStyles = css`
  padding: 5rem 0 2rem;
  background-color: ${Theme.colors.backgrounds.gray};

  nav {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .logo {
      margin: 0 5rem 6rem 0;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-grow: 1;

      img {
        width: 24rem; // IE11
        width: min(24rem, 75vw);
      }

      span {
        margin-top: 1rem;
        font-size: 1.1rem;
        font-weight: 300;
        color: ${Theme.colors.fontcolors.caption};
      }
    }

    .footer-lists {
      margin-bottom: 3rem;
      display: flex;
      justify-content: space-between;
      flex-grow: 1;
      flex-wrap: wrap;

      ul {
        padding: 0;
        list-style: none;
      }
    }
  }
`
