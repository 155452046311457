/** @jsx jsx */
import {css, SerializedStyles} from "@emotion/core"

import {Theme} from "./theme"

interface Props {
  type?: "default" | "white" | "transparent"
  size?: "small" | "default"
}

export const ButtonStyles = (props: Props | undefined): SerializedStyles[] => {
  return [
    BaseButtonStyles,
    props?.size === "small" ? SmallButtonStyles : null,
    props?.type === "white" ? WhiteButtonStyles : null,
    props?.type === "transparent" ? TransparentButtonStyles : null,
  ].filter(Boolean)
}

const BaseButtonStyles = css`
  padding: 1.5rem 4rem;
  line-height: 5rem;
  background-color: ${Theme.colors.buttoncolors.default};
  font-family: Roboto;
  font-weight: 500;
  font-size: 1.6rem;
  color: white;

  svg {
    margin-right: 1rem;
  }
`

const SmallButtonStyles = css`
  padding: 1.2rem 3rem;
  font-weight: 400;
  line-height: 4.9rem;
`

const WhiteButtonStyles = css`
  background-color: white;
  color: ${Theme.colors.fontcolors.heading};
`

const TransparentButtonStyles = css`
  padding: 1.5rem 0;
  background-color: transparent;
  color: ${Theme.colors.fontcolors.heading};
  font-size: 2rem;
  font-weight: 400;

  svg {
    margin-left: 2.5rem;
    color: ${Theme.colors.buttoncolors.default};
  }
`
