/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {Global} from "@emotion/core"
import emotionNormalize from "emotion-normalize"
import {Theme} from "./theme"

import {fontawesome} from "./fontawesome"

export const GlobalStyles = (): JSX.Element => {
  return (
    <Global
      styles={css`
        ${emotionNormalize}
        ${fontawesome}

        html {
          font-size: 10px;
          line-height: 1.6rem;
          box-sizing: border-box;
          height: 100%;
        }

        *,
        *:before,
        *:after {
          box-sizing: inherit;
        }

        body {
          height: 100%;
          width: 100%;
          color: ${Theme.colors.fontcolors.body};
          font-family: ${Theme.fonts.body};
          font-size: 1.8rem;
          line-height: 2.3rem;
          font-weight: 300;
          -webkit-font-smoothing: antialiased;
          background: ${Theme.colors.backgrounds.body};
          @media ${Theme.breakpoints.mediumDown} {
            background: white;
          }
          @media print {
            background: white;
          }

          #___gatsby {
            height: 100%;
            width: 100%;

            #gatsby-focus-wrapper {
              flex-grow: 1;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              min-height: 100%;
            }
          }
        }

        nav {
          font-family: ${Theme.fonts.navigation};
          font-weight: normal;

          a {
            color: ${Theme.colors.fontcolors.heading};
          }
        }

        main {
          margin: 9rem 0;
        }

        u {
          text-decoration: none;
        }

        h1,
        h2,
        h3,
        h4,
        h5 {
          margin: 0;
          font-family: ${Theme.fonts.heading};
        }

        h1 {
          margin: 0 0 5rem;
          font-size: 4rem;
          line-height: 6.9rem;
          font-weight: 600;
          @media ${Theme.breakpoints.mediumDown} {
            font-size: 3.5rem;
            line-height: 4.3rem;
          }
        }

        h2 {
          margin: 0 0 5rem;
          font-size: 5rem;
          line-height: 5.9rem;
          font-weight: 600;
          @media ${Theme.breakpoints.mediumDown} {
            font-size: 3.5rem;
            line-height: 4.3rem;
          }
        }

        h3 {
          margin: 0 0 5rem;
          font-size: 4rem;
          line-height: 4.7rem;
          font-weight: 500;
          @media ${Theme.breakpoints.mediumDown} {
            font-size: 2.5rem;
            line-height: 3.3rem;
          }
        }

        h4 {
          margin: 3.5rem 0 1.5rem;
          font-size: 2rem;
          line-height: 2.6rem;
          font-weight: 600;
          letter-spacing: 0.1;
        }

        h5 {
          margin: 0 0 1rem;
          font-size: 1.8rem;
          line-height: 2.4rem;
          font-weight: normal;
          @media ${Theme.breakpoints.desktop} {
            font-size: 1.7rem;
            line-height: 2.2rem;
            font-weight: 500;
          }
        }

        p {
          margin: 0;
          margin: 0 0 1.5rem;
          line-height: 3.4rem;
          font-family: ${Theme.fonts.body};
        }

        em,
        i {
          font-style: italic;
        }

        strong,
        b {
          font-weight: 600;
        }

        a {
          color: ${Theme.colors.fontcolors.link};
          text-decoration: none;
          font-weight: 400;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }

        ul,
        ol {
          margin-bottom: 1.5rem;

          li {
            margin-bottom: 0.5rem;
            line-height: 3rem;
          }
        }

        figure {
          margin: 0;
        }

        main img {
          display: block;
          max-width: 100%;
          margin: 2rem auto;

          &.align-left,
          &.align-right {
            display: inline;
            width: 40%;
          }
          &.align-left {
            margin-right: 2rem;
            float: left;
          }
          &.align-right {
            margin-left: 2rem;
            float: right;
          }
        }

        img[src=""],
        img:not([src]) {
          opacity: 0;
        }

        table {
          tr {
            th {
              text-align: left;
            }

            td {
            }

            th,
            td {
              padding: 1rem;
              background-color: ${Theme.colors.backgrounds.gray};
              vertical-align: top;
            }
          }
        }

        form {
          input,
          textarea,
          select {
            margin-bottom: 3rem;
            border: none;
            border-radius: 0.2rem;
            width: 100%;
            padding: 2.3rem 3rem;
            background-color: ${Theme.colors.backgrounds.gray};
            box-shadow: 0 0.3rem 0.3rem 0 rgba(0, 0, 0, 0.1);

            ::placeholder {
              color: #606470;
            }
          }

          textarea {
            height: 25rem;
          }

          input[type="radio"] {
            width: auto;
            height: auto;
          }

          label {
            margin: 0 1.5rem 0 0.5rem;
          }

          input[type="submit"] {
            width: auto;
            padding: 1.6rem 4.2rem;
            background-color: ${Theme.colors.identity.primary};
            color: white;
            cursor: pointer;
          }
        }
      `}
    />
  )
}
