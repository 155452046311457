/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useStaticQuery, graphql} from "gatsby"

import {FooterSection} from "../../types"

const FooterList = (section: FooterSection): JSX.Element => {
  const data = useStaticQuery(graphql`
    query FooterSectionItemsQuery {
      pages: allDatoCmsPage {
        edges {
          node {
            id
            slug
            title
            showInFooter {
              id
            }
            orderInFooter
            pageType {
              key
            }
          }
        }
      }
    }
  `)

  const relevantSectionItems = data.pages.edges
    .sort((a, b) => a.node.orderInFooter - b.node.orderInFooter)
    .filter(page => page.node.showInFooter?.id === section.id)

  return (
    <div css={FooterListStyles}>
      <div>{section.name}</div>
      <ul>
        {relevantSectionItems.map(page => (
          <li key={page.node.id}>
            <a
              href={
                page.node.pageType?.key === "homepage"
                  ? "/"
                  : `/${page.node.slug}`
              }
            >
              {page.node.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  )
}

export default FooterList

const FooterListStyles = css`
  margin: 0 5rem 0 0;
  font-weight: 600;
  font-size: 1.6rem;

  ul {
    margin: 1.2rem 0 3rem;

    li {
      font-size: 1.4rem;
      font-weight: 300;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
`
