import React from "react"
import {Helmet} from "react-helmet"

import Header from "./header"
import Footer from "./footer"

import {GlobalStyles} from "../styles/global-styles"
import "../styles/fonts.css"

import {Page, NewsItem, Document} from "../../types"

interface Props {
  title?: string
  currentPage?: Page | NewsItem | Document
  children?: React.ReactNode
}

const Layout = (props: Props): JSX.Element => {
  return (
    <>
      <Helmet>
        <title>{props.title} - Relevant - Netwerk Externe Veiligheid</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
      </Helmet>
      <GlobalStyles />
      <Header currentPage={props.currentPage} />
      <main>{props.children}</main>
      <Footer />
    </>
  )
}

export default Layout
