export const Theme = {
  fonts: {
    body: "Roboto, Arial, Helvetica, sans-serif",
    heading: "Raleway, Arial, Helvetica, sans-serif",
    navigation: "Roboto, Arial, Helvetica, sans-serif", //"SF Compact",
  },
  colors: {
    backgrounds: {
      body: "#FFFFFF",
      blue: "#EAEFF0",
      gray: "#F8F8F8",
    },
    borders: {
      default: "#CEC6B7",
    },
    buttoncolors: {
      default: "#FF9100",
      disabled: "#e2e2e2",
    },
    fontcolors: {
      body: "#1F1F1F",
      heading: "#000000",
      caption: "#474747",
      link: "#5C8C96",
      light: "#717171",
    },
    identity: {
      primary: "#FF9100",
      secondary: "#5C8C96",
    },
  },
  breakpoints: {
    mobileDown: "(max-width: 480px)",
    mobile: "(min-width: 480px)",
    small: "(min-width: 640px)",
    smallDown: "(max-width: 640px)",
    medium: "(min-width: 800px)",
    mediumDown: "(max-width: 800px)",
    desktop: "(min-width: 960px)",
    notDesktop: "(max-width: 960px)",
    largeDesktop: "(min-width: 1235px)",
  },
}
