/** @jsx jsx */
import {jsx, css} from "@emotion/core"

interface Props {
  className?: string
  children?: React.ReactNode
  text?: boolean
}

const PageWidth = (props: Props): JSX.Element => {
  return (
    <div
      className={`page-width ${props.className}`}
      css={[PageWidthStyles, props.text && PageWidthTextStyles]}
    >
      {props.children}
    </div>
  )
}

export default PageWidth

const PageWidthStyles = css`
  position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 118rem;
  padding: 0 3rem;
  @media print {
    padding: 0;
  }
`
const PageWidthTextStyles = css`
  margin-top: 6rem;
  max-width: 80rem;
`
