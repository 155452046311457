/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useStaticQuery, graphql} from "gatsby"
import {useState} from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import PageWidth from "./page-width"

import {Theme} from "../styles/theme"

import {Page, NewsItem, Document, Link} from "../../types"

interface Props {
  currentPage?: Page | NewsItem | Document
}

const Header = (props: Props): JSX.Element => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  const data = useStaticQuery(graphql`
    query PageQuery {
      pages: allDatoCmsPage(filter: {landingPage: {eq: true}}) {
        edges {
          node {
            id
            slug
            title
            menuTitle
            orderInMenu
            pageType {
              key
            }
          }
        }
      }
    }
  `)

  const sortedMenuItems = data.pages.edges
    .filter(a => a.node.orderInMenu >= 0)
    .sort((a, b) => a.node.orderInMenu - b.node.orderInMenu)

  const isCurrentSection = (
    match: Page | NewsItem | Document | Link,
    page: Page,
  ): boolean => {
    if (!match) return false

    if (match.slug === page.slug) {
      return true
    } else if (match.parentPage && isCurrentSection(match.parentPage, page)) {
      return true
    } else if (isOfPageType(match, page, "NewsItem", "nieuws")) {
      return true
    } else if (isOfPageType(match, page, "Document", "document-archief")) {
      return true
    } else {
      return false
    }
  }

  const isOfPageType = (
    match: Page | NewsItem | Document | Link,
    page: Page,
    type: "NewsItem" | "Document",
    landingType: "nieuws" | "document-archief",
  ): boolean => {
    if (!match.id) return false
    return match.id.indexOf(type) > 0 && page.slug == landingType
  }

  return (
    <header css={HeaderStyles}>
      <PageWidth>
        <figure className="logo">
          <a href="/">
            <img src="/logo.svg" />
            <img className="mobile" src="/logo-small.svg" />
          </a>
        </figure>

        <div
          className="mobile-menu"
          onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
        >
          <FontAwesomeIcon icon={["fas", "bars"]} />
        </div>

        <nav
          className={mobileMenuOpen ? "mobile-menu-open" : "mobile-menu-closed"}
          css={[HeaderStyles].push(
            sortedMenuItems.length > 0 ? HeaderStyles : null,
          )}
        >
          <ul>
            {sortedMenuItems.map(page => (
              <li key={page.node.id}>
                <a
                  href={
                    page.node.pageType?.key === "homepage"
                      ? "/"
                      : `/${page.node.slug}`
                  }
                  className={
                    isCurrentSection(props.currentPage, page.node)
                      ? "active"
                      : ""
                  }
                >
                  {page.node.menuTitle || page.node.title}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </PageWidth>
    </header>
  )
}

export default Header

const HeaderStyles = css`
  position: fixed;
  z-index: 99;
  top: 0;
  height: 9rem;
  width: 100%;
  background: white;
  transition: top 0.2s ease-in-out;

  .page-width {
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    figure.logo {
      height: 3.5rem;

      a {
        display: block;
        width: 100%;
        height: 100%;

        img {
          height: 100%;
          &.mobile {
            width: 12rem;
            display: none;

            @media ${Theme.breakpoints.notDesktop} {
              display: block;
            }
          }
          &:not(.mobile) {
            width: 30rem; // IE11
            width: min(60vw, 30rem);

            @media ${Theme.breakpoints.notDesktop} {
              display: none;
            }
          }
        }
      }
    }

    .mobile-menu {
      display: block;
      font-size: 2.2rem;
      @media ${Theme.breakpoints.medium} {
        display: none;
      }
    }

    nav {
      ul {
        height: 100%;
        margin: 0;
        list-style-type: none;
        display: flex;

        li {
          margin: 0 3rem 0 0; // IE11
          margin: 0 min(3vw, 5rem) 0 0;
          display: flex;
          align-items: center;
          font-size: 1.6rem;
          &:last-child {
            margin-right: 0;
          }
        }

        a.active {
          font-weight: 600;
          color: ${Theme.colors.identity.primary};
        }
      }

      @media ${Theme.breakpoints.mediumDown} {
        position: absolute;
        width: 100%;
        left: 0;
        top: 100%;
        height: calc(100vh - 9rem);
        background: white;
        z-index: 10;
        transition: left 0.3s ease-in-out;

        &.mobile-menu-open,
        &.mobile-menu-closed {
          left: 0;

          ul {
            flex-direction: column;
            padding: 4rem 0 0 0;
            justify-content: flex-start;
            align-items: center;

            li {
              margin-bottom: 5vh;

              a {
                font-size: 2.5rem;
              }
            }
          }
        }

        &.mobile-menu-closed {
          left: 100vw;
        }
      }
    }
  }
`
